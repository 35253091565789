import React from 'react';
import { useParams } from 'react-router-dom';
import Ariane from '../components/Common/Ariane';
import ContentModuleStatistic from '../components/Module/ContentModuleStatistic';

export default function Statistique() {

    const links = [
        {name:"Accueil", target:"/"},
        {name:"Statistiques",active:true},
    ]

    return (
        <div>
            <Ariane links={links} title="Statistiques"/>
            <ContentModuleStatistic />
        </div>
    )
}
