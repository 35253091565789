import React, { useContext } from 'react'

import ContentLoader from 'react-content-loader'
import _ from 'lodash'
import { AppContext } from '../../App'
import Slider from "react-slick";

const TestimonialsLoader = () => (
    <ContentLoader viewBox="0 0 380 400">
        <rect x="15" y="0" rx="5" ry="7" width="100%" height="100" />
        <rect x="15" y="110" rx="5" ry="7" width="100%" height="100" />
    </ContentLoader>
)

export default function Testimonials() {
    const { appState: { defaultdatas } } = useContext(AppContext)
    console.log(defaultdatas);
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        focusOnSelect: true
    };
    return (
        <div>
            {/* <div class="container">
                <div class="section-title ">
                    <h5>Témoignages</h5>
                </div>
            </div> */}

            <section id="testimonial" class="bg_cover pt-25 pb-50 testimonial" style={{ backgroundImage: 'url("../../assets/img/bg-3.jpg")' }}>
    
                        <Slider {...settings} >
                            {defaultdatas && defaultdatas.temoignages && defaultdatas.temoignages.data && defaultdatas.temoignages.data.length > 0 && defaultdatas.temoignages.data[0].pages ? defaultdatas.temoignages.data[0].pages.map(data => (
                                <div className=" text-center mt-40 text-white" key={data.id} >
                                    <div className="text-white" dangerouslySetInnerHTML={{ __html: data.content }}></div>

                                </div>

                            )) : <h5>Aucun témoignage disponible</h5>}
                        </Slider>

            </section>
        </div>
    )
}
