import React from "react";
import Navigation from "../components/Header/Navigation";
import SearchBar from "../components/Header/SearchBar";

const Header = () => {

    return (
        <>
            <SearchBar />
           <Navigation  />
        </>

    )
}

export default Header;