import React, { useEffect, useState } from 'react'
import Loader from "../Common/Loader";
import youtube from "../../service/youtube";


export default function ContentModuleVideos() {
    // const [parent] = useState(null)
    const [loading,setLoading] = useState([])
    //const [currentPage, setCurrentPage] = useState('')
    const [nextToken, setNextToken] = useState('')
    const [prevToken, setPrevToken] = useState('')
    const [next, setNext] = useState(false)
    const [prev, setPrev] = useState(false)

    const [moduleVideos, setModuleVideos] = useState([])
    useEffect(() => {
        setLoading(true)
        youtube.get('/search',{
            params :{
                pageToken:nextToken
            }
        }).then(res => {
            setModuleVideos(res.data.items);
            setNextToken(res.data.nextPageToken)
        })
    },[next])

    useEffect(() => {
        setLoading(true)
        youtube.get('/search',{
            params :{
                pageToken:prevToken
            }
        }).then(res => {
            setModuleVideos(res.data.items);
            setPrevToken(res.data.prevPageToken)
        })
    },[prev])

    return (
        <section id="teachers-page" class="pt-90 pb-120 gray-bg">
            {loading &&<Loader/>}
            <div class="container">
                <div class="row">
                    {
                        moduleVideos.map(module => (
                            <div class="col-lg-6 col-sm-12" key={module.id.videoId}>
                                <div class="single-teachers mt-30 text-center">
                                    <div class="image">
                                        {/* <img src={module.snippet.thumbnails.medium.url} alt="Teachers" /> */}
                                        <iframe className="styles"
                                            src={`https://www.youtube.com/embed/${module.id.videoId}`}>
                                        </iframe>
                                    </div>
                                    <div class="cont">
                                        <a className="cursor" href={`https://www.youtube.com/watch?v=${module.id.videoId}`}>{module.snippet.title}</a>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <nav className="courses-pagination mt-50 pb-3">
                            <ul className="pagination justify-content-center">
                                <li className="page-item cursor">
                                    <a onClick={() => prevToken!=null && setPrev(!prev)} aria-label="Précédent" >
                                        <i className="fa fa-angle-left"></i>
                                    </a>
                                </li>
                                <li className="page-item cursor">
                                    <a onClick={() => nextToken!=null && setNext(!next)} aria-label="Suivant">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
    )
}
