import React from 'react';
import Links from "../components/Footer/Links";
import '../assets/css/style-acces-rapide.css';

const PUBLIC_URL = process.env.PUBLIC_URL

export default function Footer() {
    return (
        <>
            <footer className="site-footer">
                <div className="container">
                    <div className="row pb-5 pt-3">
                        <div className="col-md-3 pt-5 mt-2">
                            {/*white-img*/}
                            <img src={`${PUBLIC_URL}/assets/img/Logo-FNDA-BENIN.jpg`} alt=" white-img footer logo" className="d-inline pb-3 footer-logo " />
                        </div>
                        <div className="col-md-7">
                            <Links />
                        </div>

                        <div className="col-md-2 pt-5 mt-2">
                            <img src={`${PUBLIC_URL}/assets/images/europeanunion.png`} alt="footer logo" className="d-inline pb-3 ue footer-logo pull-right" />
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 small_screen" style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "stretch", marginBottom: "20px" }}>
                            <a href="/page/recrutement" className="text-white font-weight-bold">Recrutement</a>

                            <a href="/page/conditions-generales" className="text-white font-weight-bold">Conditions Générales</a>

                            <a href="/page/mentions-legales" className="text-white font-weight-bold">Mentions Légales</a>

                            <a href="/partenaire_strategique" className="text-white font-weight-bold">Partenaires Stratégiques</a>

                            <a href="/page/poles-de-developpement-" className="text-white font-weight-bold">Pôles de développement</a>

                            <a href="/faqs" className="text-white font-weight-bold">FAQ</a>
                        </div>

                        <div className="row acces-rapide">
                            <div className="col-md-3">
                                <ul>
                                    <li><a href="http://fnda.agriculture.gouv.bj/page/FNDA/mot-du-directeur-general" className="text-white size-text">Mot du directeur général</a></li>
                                    <li><a href="http://fnda.agriculture.gouv.bj/page/FNDA/quest-ce-que-le-fnda-" className="text-white size-text">Présentation</a></li>
                                    <li><a href="http://fnda.agriculture.gouv.bj/page/FNDA/mission-vision-et-valeurs" className="text-white size-text">Vision, Mission et Valeurs</a></li>
                                    <li><a href="http://fnda.agriculture.gouv.bj/services" className="text-white size-text">Organes</a></li>
                                    <li><a href="http://fnda.agriculture.gouv.bj/section/FNDA/Guichets" className="text-white size-text">Nos Guichets</a></li>
                                    <li><a href="http://fnda.agriculture.gouv.bj/page/FNDA/organigramme" className="text-white size-text">Organigramme</a></li>
                                    <li><a href="http://fnda.agriculture.gouv.bj/section/FNDA/Partenaires" className="text-white size-text">Patenaires</a></li>
                                </ul>
                            </div>
                            <div className="col-md-3">
                                <ul>
                                    <li><a href="http://fnda.agriculture.gouv.bj/processusselection" className="text-white size-text">Processus de selection</a></li>
                                    <li><a href="http://fnda.agriculture.gouv.bj/page/OBTENIR UN FINANCEMENT/comment-obtenir-une-subvention-" className="text-white size-text">Comment obtenir une subvention ?</a></li>
                                    <li><a href="http://fnda.agriculture.gouv.bj/page/OBTENIR UN FINANCEMENT/comment-acceder-a-un-credit-" className="text-white size-text">Comment accéder à un crédit ?</a></li>
                                    <li><a href="http://fnda.agriculture.gouv.bj/guichets/OBTENIR UN FINANCEMENT/@guichet3" className="text-white size-text">Comment obtenir un refinancement ?</a></li>
                                    <li><a href="http://fnda.agriculture.gouv.bj/section/OBTENIR UN FINANCEMENT/Projet financés" className="text-white size-text">Projets Financés</a></li>

                                </ul>
                            </div>
                            <div className="col-md-3">
                                <ul>
                                    <li><a href="http://fnda.agriculture.gouv.bj/page/OBTENIR UN FINANCEMENT/point-du-partenariat-avec-les-psf" className="text-white size-text">Point du partenariat avec les PSF</a></li>
                                    <li><a href="http://fnda.agriculture.gouv.bj/section/home/Actualités" className="text-white size-text">Actualités</a></li>
                                    <li><a href="http://fnda.agriculture.gouv.bj/videotheque" className="text-white size-text">Vidéo</a></li>
                                    <li><a href="http://fnda.agriculture.gouv.bj/mediatheque" className="text-white size-text">Galerie Photo</a></li>
                                    <li><a href="http://fnda.agriculture.gouv.bj" className="text-white size-text">Archives</a></li>
                                </ul>
                            </div>
                            <div className="col-md-3">
                                <ul>
                                    <li><a href="http://fnda.agriculture.gouv.bj/section/DOCUMENTATIONS/Communiqués" className="text-white size-text">Communiqués</a></li>
                                    <li><a href="http://fnda.agriculture.gouv.bj/section/DOCUMENTATIONS/Documents de référence" className="text-white size-text">Documents de référence</a></li>
                                    <li><a href="http://fnda.agriculture.gouv.bj/section/DOCUMENTATIONS/Rapports" className="text-white size-text">Rapports</a></li>
                                    <li><a href="http://fnda.agriculture.gouv.bj/section/DOCUMENTATIONS/Avis d'appel à la manifestation" className="text-white size-text">Avis d'appels à la manifestation</a></li>
                                    <li><a href="http://fnda.agriculture.gouv.bj/section/DOCUMENTATIONS/Contacts" className="text-white size-text">Contacts</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-12 text-center">
                            <p className="text-center text-white">
                                © Ministère de l'Agriculture, de l'Elevage et de la Peche - 2021 | <a href="/page/mentions-legales" className="politique" >Politique de confidentialité</a>
                        </p>
                        </div>

                        <small class="text-white text-center mt-3 ml-1 mr-1 mb-0">Ce site a été créé et maintenu avec le soutien financier de l’Union européenne. Son contenu relève de la seule responsabilité du FNDA sous la supervision de la DSI/MAEP et n’engage par la responsabilité de l’Union européenne.</small>

                    </div>
                </div>
                <div className="flag-look-like">
                    <div className="col green-block"></div>
                    <div className="col yellow-block"></div>
                    <div className="col red-block"></div>
                </div>
            </footer>
        </>
    )
}
