import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

const Ariane = ({title}) => {

    return (

        <section id="page-banner" class="pt-35 pb-30 bg_cover" data-overlay="8">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="page-banner-cont">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#">Accueil</a></li>
                                <li class="breadcrumb-item active" aria-current="page">{title}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}

Ariane.propTypes ={
    title: PropTypes.string.isRequired,
}
export default Ariane;