import React, { useEffect, useState } from 'react'
import Ariane from '../components/Common/Ariane';
import {getResource} from '../service/api';
import { useParams } from 'react-router';
import ContentModulePhotos from '../components/Module/ContentModulePhotos';

export default function Mediatheque() {

    return (
        <>
            <Ariane title="Médiathèque"/>
            <ContentModulePhotos />
        </>
    )
}
