import React, { useEffect, useState } from 'react'
import { getResource } from '../../service/api'
import { useHistory } from 'react-router-dom';
import ContentModulePhotosItems from './ContentModulePhotosItems';
import ContentLoader, { Facebook } from 'react-content-loader'
const directory = process.env.REACT_APP_UPLOAD_DIRECTORY

const MyLoader = () => (
    <ContentLoader viewBox="0 0 380 400">
      {/* Only SVG shapes */}    
      <rect x="15" y="0" rx="5" ry="7" width="170" height="170" />
      <rect x="195" y="0" rx="5" ry="7" width="170" height="170" />
      <rect x="15" y="180" rx="5" ry="7" width="170" height="170" />
      <rect x="195" y="180" rx="5" ry="7" width="170" height="170" />
    </ContentLoader>
  )

  
export default function ContentModulePhotos() {
    const [parent, setParent] = useState(null)
    const [loading, setLoading] = useState(true)
    const history = useHistory();

    const [modulePhotos, setModulePhotos] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [numberPage, setNumberPage] = useState([])
    useEffect(() => {
        setLoading(true)
        getResource(`modules_all?type_module=medias&public=1${parent ? `&parent_id=${parent.id}` : ''}`).then(res => {
            let modules = res.data.data;
            if (!parent) modules = modules.filter(m => m.parent_id == null)
            setModulePhotos(modules);
            setLoading(false)
            let array_pages = Math.ceil(parseInt(res.data.total) / parseInt(res.data.per_page))
            array_pages = Array.from(Array(array_pages).keys())
            setNumberPage(array_pages);
        })

    }, [parent])

    

    const access_children = module => {
        setParent(module)
        // history.push(`/mediatheque-items/${module.id}`)
    }

    return (
        <section id="teachers-page" class="pt-20 pb-20 ">
     
            <div class="container">
                {parent && <button class="btn" onClick={() => setParent(parent.parents)}><i className="fa fa-chevron-circle-left"></i> Retour</button>}
                <div class="row ">
                {loading && <MyLoader />}

                    {modulePhotos.length >0  &&<div className={`${parent ?'col-md-3 ':'col-md-12'} section-title`}>
                        <h5 className="mb-10">Albums </h5>

                        <div className="bg-white pl-10 pr-10 album">
                            <div class="row  bg-white">
                            {/* {loading && <MyLoader />} */}
                                {
                                   !loading && modulePhotos.map(module => (
                                        <div class={`${!parent && 'col-sm-4 '}`} key={module.id}>
                                            <div class="single-teachers cursor text-center mt-10" onClick={() => access_children(module)}>
                                                <div class="image">
                                                    <img src={module.medias.length>0 ? `${directory}/${module.medias[0].fichier.filename}`:'/assets/images/album-default.jpeg'} alt={module.name} />
                                                    
                                                </div>
                                                <div class="cont">
                                                    <a className="cursor" >{module.name}</a>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                            {numberPage > 1 && <div className="row">
                                <div className="col-lg-12">
                                    <nav className="courses-pagination mt-10 pb-3">
                                        <ul className="pagination justify-content-center">
                                            <li className="page-item cursor">
                                                <a onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} aria-label="Précédent" >
                                                    <i className="fa fa-angle-left"></i>
                                                </a>
                                            </li>
                                            {
                                                numberPage.map((n) => (
                                                    <li key={`page${n + 1}`} className="page-item cursor"><a className={currentPage == (n + 1) && 'active'} onClick={() => setCurrentPage(n + 1)}>{n + 1}</a></li>
                                                ))
                                            }
                                            <li className="page-item cursor">
                                                <a onClick={() => currentPage < numberPage.length && setCurrentPage(currentPage + 1)} aria-label="Suivant">
                                                    <i className="fa fa-angle-right"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
}
                        </div>

                    </div>
}
                    {parent && <div className={`${modulePhotos.length>0 ?'col-md-9 ':'col-md-12 '} section-title `}>
                        <h5 className="mb-10">Photos {parent && parent.name} </h5>    
                        <div className="bg-white pl-10 pr-10 album">
                        <ContentModulePhotosItems module={parent}/>
                        </div>
                    </div>}




                </div>


            </div>
        </section>



    )
}
