import React from 'react';
import Ariane from '../components/Common/Ariane';
import ContentModulePartenaire from '../components/Module/ContentModulePartenaire'

export default function PartenaireStrategique() {


    return (
        <div>
            <Ariane  title="Partenaires Strategiques"/>
            <ContentModulePartenaire/>
        </div>
    )
}
