import React from 'react';
import notfound from '../../assets/images/notfound.png';

const NotFound = () => {
    return (
        <div id="simple-page" class="pt-90 pb-120 bg-white">
            <div class="container">
                <div class="row">

                    <div class="col-md-4">
                        <h4 class="text-center text-secondary">Error</h4>
                        <h1 class="text-center errocode">404</h1>
                        <h3 id="notfoundtext" class="text-secondary text-center">
                            Désolé, mais la page que vous recherchez n'existe pas.
                    </h3>
                        <a href="/" class="text-center mt-4 w-100"><i class="fa fa-home"></i> Retourner a la page d'acceuil</a>
                    </div>
                    <div class="col-md-8">
                        <img src={notfound} alt="notfound.png" id="notfound-img"
                            class="img-fluid mx-auto d-block" />
                    </div>
                </div>
            </div>
        </div>

    );
}

export default NotFound