import React, { useEffect, useState } from 'react';
import { getResource } from "../../service/api";
import moment from 'moment'
import { useParams } from 'react-router-dom'
import {Alert} from 'react-bootstrap';


const directory_doc = process.env.REACT_APP_UPLOAD_DIRECTORY;


export default function ContentModuleNews({ module }) {

    const [section, setSection] = useState(1)
    const [actualites, setActualites] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [numberPage, setNumberPage] = useState([])
    const [query, setQuery] = useState("");


    

    const {subject} = useParams();

    useEffect(() => {
        if(subject == 2){
            setSection(2)
        }
    }, [])


    useEffect(() => {
        setCurrentPage(1)
    }, [section])

    useEffect(() => {
        loadData()
    }, [currentPage, section])

    const loadData = () => {
        let type = section === 1 ? 'nouvelle' : 'communique'
        getResource(`news?type=${type}&page=${currentPage}&public=1`).then(res => {
            setActualites(res.data.data)
            let array_pages = Math.ceil(parseInt(res.data.total) / parseInt(res.data.per_page))
            array_pages = Array.from(Array(array_pages).keys())
            setNumberPage(array_pages);
        })
    }

    const HandleSubmit = async(event) => {
        event.preventDefault();

        if(event) {
            let type = section === 1 ? 'nouvelle' : 'communique'

                await getResource(`news?type=${type}&title=${event.target.value}&page=${currentPage}&public=1`).then((res) => {
                    setActualites(res.data.data);
                })

        }
    
    }
    


    return (
        <section id="courses-part" className="pt-120 pb-120 bg-light">
            <div className="container bg-white">
                <ul className="nav nav-tabs nav-fill pt-3 " id="myTab" role="tablist">
                    <li className="nav-item">
                        <a className={`nav-link ${(section === 1) && 'active show'}`} id="home-tab" data-toggle="tab" onClick={() => setSection(1)} role="tab"
                            aria-controls="actualite" aria-selected="true">Actualités</a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link ${(section === 2) && 'active show'}`} id="profile-tab" data-toggle="tab" onClick={() => setSection(2)} role="tab"
                            aria-controls="communiquer" aria-selected="false">Publications</a>
                    </li>

                </ul>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="courses-top-search">

                            <div className="courses-search float-right">
                                <form >
                                    <input onChange={HandleSubmit} type="text" placeholder="Rechercher une information ..."/>
                                    <button disabled type="submit"><i className="fa fa-search"></i></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="tab-content" id="newsContent">
                    <div className={`tab-pane fade  ${(section === 1) && 'active show'}`} role="tabpanel" aria-labelledby="actualite-tab">
                        <div className="row">
                            {
                                typeof actualites !== 'undefined' && actualites.length > 0 ? actualites.map(data => (
                                    <div className="col-lg-4 col-md-6" key={data.id}>
                                        <div >
                                            <div className="single-course">
                                                <div className="thum">
                                                    {data.fichier && 
                                                    <div className="image">
                                                        <img src={`${directory_doc}/${data.fichier.filename}`} alt={data.title}/>
                                                        
                                                    </div>}

                                                </div>
                                                <div className="cont">
                                                    <a href={`/news/${data.permalink}`}>
                                                        <h4>{data.title}</h4>
                                                    </a>
                                                    <div className="course-teacher">

                                                        <div className="name">
                                                            <a href="#">
                                                                <h6><i className="fa fa-calendar"></i> Publiée le {moment(data.created_at).format('DD-MM-YYYY')}</h6>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )): <Alert variant="secondary"><h3>Pas de donnée disponible</h3></Alert>
                            }

                        </div>
                    </div>
                    <div className={`tab-pane fade  ${(section === 2) && 'active show'}`} role="tabpanel" aria-labelledby="communiquer-tab">
                        <div id="simple-page" className=" pb-10 bg-white">
                            {
                               typeof actualites !== 'undefined' && actualites.length > 0 ? actualites.map(a => (
                                    <div className="row mt-4 border-bottom p-4" key={a.id}>
                                        <div className="col-sm-12 col-md-2 p-0 bd-highlight  ">
                                            <div className="doc-infos light adapt nowrap left-20-in right-20-in ">
                                                <small className="d-block semibold bottom-15 adapt pb-2"><i className="fa fa-calendar"></i> {moment(a.created_at).format('DD.MM.YYYY')}</small>

                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-8 pr-3 pb-0 bd-highlight">
                                            <div className="header adapt">
                                                <h3 className="h4 semibold adapt bottom-5"><a href={`/news/${a.permalink}`}  className="doc-title w-100 text-dark">{a.title}</a></h3>
                                            

                                                <div dangerouslySetInnerHTML={ {__html:a.content.slice(0,800)} }></div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-2 pb-0 bd-highlight`">
                                            <div className="action-btn">
                                             
                                                <a href={`/news/${a.permalink}`} className="pb-1 pr-0 btn btn-link text-uppercase font-weight-bold download">
                                                    Lire plus
                                    </a>
                                            </div>
                                        </div>
                                    </div>

                                )): <Alert className="col-md-6" variant="secondary"><h3>Pas de donnée disponible</h3></Alert>
                            }

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <nav className="courses-pagination mt-50 pb-3">
                            <ul className="pagination justify-content-center">
                                <li className="page-item cursor">
                                    <a onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} aria-label="Précédent" >
                                        <i className="fa fa-angle-left"></i>
                                    </a>
                                </li>
                                {
                                    numberPage.map((n) => (
                                        <li key={`page${n + 1}`} className="page-item cursor"><a className={currentPage == (n + 1) && 'active'} onClick={() => setCurrentPage(n + 1)}>{n + 1}</a></li>
                                    ))
                                }
                                <li className="page-item cursor">
                                    <a onClick={() => currentPage < numberPage.length && setCurrentPage(currentPage + 1)} aria-label="Suivant">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

            </div>

        </section>

    )
}
