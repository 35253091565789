import React from 'react'
import moment from 'moment';

const directory_doc = process.env.REACT_APP_UPLOAD_DIRECTORY;


export default function SearchViewContent({data}) {

    return (
        <div>

            {typeof data.news !== 'undefined' && data.news.length > 0 ?

                <section id="course-part" class="pt-50 pb-50  animated slideInLeft">
                    <div class="container">
                

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="section-title pb-45">
                                    <h5>Actualites</h5>
                                </div>
                            </div>
                        </div>
                    
                        { data.news && data.news.map(news => (
                                <div className="row mt-4 border-bottom p-4" key={news.id}>
                                        <div className="col-sm-12 col-md-2 p-0 bd-highlight  ">
                                            <div className="doc-infos light adapt nowrap left-20-in right-20-in ">
                                                <small className="d-block semibold bottom-15 adapt pb-2">Publié le <i className="fa fa-calendar"></i> {moment(news.created_at).format('DD.MM.YYYY')}</small>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-7 pr-3 pb-0 bd-highlight">
                                            <div className="header adapt">
                                                <h3 className="h4 semibold adapt bottom-5"><a className="doc-title text-dark">{news.title}</a></h3>
                            
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-3 pb-0 bd-highlight`">
                                                <a href={`/news/${news.permalink}`} type="button"
                                                    className="pb-1 pl-0 btn btn-link text-uppercase d-inline-flex font-weight-bold pull-right justify-content-center align-items-center">
                                                    Lire</a> 

                                        </div>
                                </div>
                            ))
                        }                
                    </div>
                </section>

            : ''
            }
    

        {typeof data.page !== 'undefined' && data.page.length > 0 ?
            <section id="course-part" class="pt-50 pb-50  animated slideInLeft">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="section-title pb-45">
                                <h5>Pages</h5>
                            </div>
                        </div>
                    </div>

                    { data.page && data.page.map(page => (
                        <div className="row mt-4 border-bottom p-4" key={page.id}>
                                <div className="col-sm-12 col-md-2 p-0 bd-highlight  ">
                                    <div className="doc-infos light adapt nowrap left-20-in right-20-in ">
                                        <small className="d-block semibold bottom-15 adapt pb-2">Publié le <i className="fa fa-calendar"></i> {moment(page.created_at).format('DD.MM.YYYY')}</small>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-7 pr-3 pb-0 bd-highlight">
                                    <div className="header adapt">
                                        <h3 className="h4 semibold adapt bottom-5"><a className="doc-title text-dark">{page && page.title}</a></h3>
                                        
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-3 pb-0 bd-highlight">
                                   
                                        <a href={`/page/${page.permalink}`} type="button" 
                                            className="pb-1 pl-0 btn btn-link text-uppercase d-inline-flex font-weight-bold pull-right justify-content-center align-items-center">
                                            Lire</a> 
                                        
                                </div>
                        </div>
                    ))
                }
                </div>
            </section>
             : ''
            }
    
    {typeof data.partner !== 'undefined' && data.partner.length > 0 ?
        <section id="course-part" className="pt-50 pb-50  animated slideInLeft">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="section-title pb-45">
                        <h5>Partners</h5>
                    </div>
                </div>
            </div>

            {data.partner && data.partner.map(partner => (
                <div className="row mt-4 border-bottom p-4" key={partner.id}>
                        <div className="col-sm-12 col-md-2 p-0 bd-highlight  ">
                            <div className="doc-infos light adapt nowrap left-20-in right-20-in ">
                                <small className="d-block semibold bottom-15 adapt pb-2">Publié le <i className="fa fa-calendar"></i> {moment(partner.created_at).format('DD.MM.YYYY')}</small>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-7 pr-3 pb-0 bd-highlight">
                            <div className="header adapt">
                                <h3 className="h4 semibold adapt bottom-5"><a className="doc-title text-dark">{partner && partner.name}</a></h3>
                         
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-3 pb-0 bd-highlight`">
                         
                                <a href={`${partner.website_link}`} type="button" target="_blank"
                                    className="pb-1 pl-0 btn btn-link text-uppercase d-inline-flex font-weight-bold pull-right justify-content-center align-items-center">
                                    Consulter
                        </a> 
                                
                        </div>
                </div>
            ))
        }
        </div>
    </section>
    : ''
    }

    
    
    
    {typeof data.documentation !== 'undefined' && data.documentation.length > 0 ?
        <section id="course-part" class="pt-50 pb-50  animated slideInLeft">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="section-title pb-45">
                        <h5>Documentations</h5>
                    </div>
                </div>
            </div>

            { data.documentation && data.documentation.map(documentation => (
                <div className="row mt-4 border-bottom p-4" key={documentation.id}>
                        <div className="col-sm-12 col-md-2 p-0 bd-highlight  ">
                            <div className="doc-infos light adapt nowrap left-20-in right-20-in ">
                                <small className="d-block semibold bottom-15 adapt pb-2">Publié le <i className="fa fa-calendar"></i> {moment(documentation.created_at).format('DD.MM.YYYY')}</small>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-7 pr-3 pb-0 bd-highlight">
                            <div className="header adapt">
                                <h3 className="h4 semibold adapt bottom-5"><a className="doc-title text-dark">{documentation && documentation.title}</a></h3>
                           
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-3 pb-0 bd-highlight`">
                                 
                                <a href={`${directory_doc}/${documentation.fichier.filename}`} className="pb-1 pl-0 btn btn-link text-uppercase d-inline-flex font-weight-bold pull-right justify-content-center align-items-center" type="button">
                                    Telecharger
                        </a>
                         
                        </div>
                </div>
            ))
        }
        </div>
    </section>
    : ''
    }

    </div>
    )
}
