import React, { useContext } from 'react';
import { AppContext } from '../../App';

import Slider from "react-slick";

import ContentLoader from 'react-content-loader'
import _ from 'lodash'

const PartnersLoader = () => (
    <ContentLoader viewBox="0 0 380 60">
        <rect x="15" y="0" rx="5" ry="7" width="50" height="50" />
        <rect x="75" y="0" rx="5" ry="7" width="50" height="50" />
        <rect x="135" y="0" rx="5" ry="7" width="50" height="50" />
        <rect x="195" y="0" rx="5" ry="7" width="50" height="50" />
        <rect x="255" y="0" rx="5" ry="7" width="50" height="50" />
        <rect x="315" y="0" rx="5" ry="7" width="50" height="50" />
    </ContentLoader>
)


const directory_doc = process.env.REACT_APP_UPLOAD_DIRECTORY;

export default function Partner() {
    const { appState: { defaultdatas } } = useContext(AppContext)

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (
        <div id="patnar-logo" className=" pb-10  light-bg">
            <div className="container">
                <div className="row ">
                    <div className="col-lg-12 section-title">
                    <h5 className="mt-20">Partenaires</h5>
                    {!defaultdatas && <PartnersLoader/>}

                       <Slider {...settings} >
                            {defaultdatas && defaultdatas.partners.data.map(data => (
                                <div className=" text-center mt-40" key={data.id}>
                                    <img className='img-fluid' src={`${directory_doc}/${data.fichier.filename}`} alt="Logo" />
                                </div>
                              
                            ))}
                        </Slider>
                    </div>


                </div>
            </div>
        </div>
    )
}
