import React, { useEffect, useState } from 'react'
import { getResource } from '../../service/api'
import { postResource } from '../../service/api'
import {Alert} from 'react-bootstrap';
import { useParams } from 'react-router-dom'


export default function ContentModuleContact({ module }) {

    const [contacts, setContacts] = useState([])
    const [data, setData] = useState([])
    const [contactEmail, setContactEmail] = useState([])
    const [alert, setAlert] = useState(null);
    const [button, setButton] = useState(<i className="fa fa-paper-plane"></i>);
    const [disable, setDisable] = useState('');
    const {subject} = useParams();
    const [code, setCode] = useState(null);


    useEffect(() => {
        getResource(`contacts?module_id=${module.id}&public=1`).then(res => {
           setContacts(res.data.data)
       })

   }, [])

   const Reset = () => {
       document.getElementById("contact-form").reset();
   }


    const onSubmit = (event) => {
        event.preventDefault();

       
       const mailValue = document.getElementById("getMail").value;

         if (/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]{2,}[.][a-z]{2,3}$/.test(mailValue)){ 

        

        setButton(<i className="fa fa-spinner fa-pulse fa-1x fa-fw"></i>);
        setDisable("disabled");


            postResource('contactMail',data).then(() => {
                setAlert({ success: true, message: 'Votre message a été prise en compte' })
                setButton(<i className="fa fa-paper-plane"></i>)
                setDisable("")
                setCode({color:'#fff', 
                backgroundColor:'#4AA340'})

              }, () => {
                setAlert({ success: false, message: 'Erreur l\'ors de l\'envoie' });
                setButton(<i className="fa fa-paper-plane"></i>)
                setDisable("")
              }).finally(() => {
                setTimeout(() => {
                  setAlert(null);
                  Reset();
                  setData(null);
                }, 10000);
                
              });

         }else{
            
            setAlert({ success: false, message: 'Votre adresse email est invalide' });
         }
        }


    return (
        <section className="pt-30 pb-30 white-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="contact-from mt-30">
                            <div className="section-title">
                                <h5>Contactez-Nous</h5>
                            </div>
                            {
                                alert && <Alert style={code ? code : {color: 'black'}}   variant={alert.success ? 'success' : 'danger'} >
                                { alert.message }
                                </Alert>
                            }
                            <div className="main-form pt-45">
                                <form id="contact-form" onSubmit={onSubmit} method="post" data-toggle="validator">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="single-form form-group">
                                                <input name="name" type="text" onInput={ (e) => setData({...data,name:e.target.value})} placeholder="Votre nom"
                                                    data-error="Ceci est un champ obligatoire." required="required" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-group">
                                                <input id="getMail" name="email" type="email" onInput={ (e) => setData({...data,email:e.target.value})} placeholder="Email"
                                                    data-error="Ceci est un champ obligatoire." required="required" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-group">
                                                <input name="subject" type="text" onInput={ (e) => setData({...data,sujet:e.target.value})} placeholder="Sujet"
                                                    data-error="Ceci est un champ obligatoire." required="required" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-group">
                                                <input name="phone" type="text" onInput={ (e) => setData({...data,telephone:e.target.value})} placeholder="Telephone"
                                                    data-error="Ceci est un champ obligatoire." required="required" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="single-form form-group">
                                                <textarea name="message" onInput={ (e) => setData({...data,message:e.target.value})} placeholder="Message"
                                                    data-error="Ceci est un champ obligatoire."
                                                    required="required"></textarea>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <p className="form-message"></p>
                                        <div className="col-md-12">
                                        <div class="single-form">
                                                {
                                                    disable == 'disabled' ? <button disabled type="submit" className="main-btn">En cours {button}</button> :
                                                    <button type="submit" className="main-btn">Envoyer {button}</button> 
                                                }
                                                
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5" >
                        <div className="contact-address mt-30">
                        {contacts.map(c => (
                            <ul>
                            <li>
                                <div className="single-address">
                                    <div className="icon">
                                        <i className="fa fa-phone"></i>
                                    </div>
                                    <div className="cont">
                                        <p>{c.phone}</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="single-address">
                                    <div className="icon">
                                        <i className="fa fa-envelope"></i>
                                    </div>
                                    <div className="cont">
                                        <p>{c.email}</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="single-address">
                                    <div className="icon">
                                        <i className="fa fa-link"></i>
                                    </div>
                                    <div className="cont">
                                        <a href={c.website} target="_blank">{c.website}</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        ))} 
                        </div>
                        <div className="map mt-30">
                            <div id="contact-map"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    )
}
