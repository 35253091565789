import React, { useEffect, useState } from 'react'
import { getResource } from '../../service/api'
import ContentLoader from 'react-content-loader'
import _ from 'lodash'
import "../../assets/css/page.css";

const EtapesLoader = () => (
    <ContentLoader viewBox="0 0 380 400">
        <rect x="15" y="0" rx="5" ry="7" width="100%" height="60" />
        <rect x="15" y="75" rx="5" ry="7" width="100%" height="60" />
        <rect x="15" y="150" rx="5" ry="7" width="100%" height="60" />
    </ContentLoader>
)

export default function ContentModuleFaqs() {
    const [etapes, setEtapes] = useState(null)

    useEffect(() => {
        getResource(`modules?tag=@faqs`).then(res => {
            let pages = res.data.data[0].pages;
            setEtapes(pages)
        })

    }, [])


    return (
        <div class="container mt-35 mb-35 page">
            <div class="accordion etapes row" id="accordionExample">
                <div class="col-md-12">
                    {!etapes && <EtapesLoader />}
                    {etapes && _.sortBy(etapes, ['title']).filter(etape => etape.public == 1).map((g, index) => (
                        <div class="card" key={index}>
                            <div class="card-header colors bg-success" id={`heading${g.id}`}>
                                <button class="btn btn-link text-left text-white accordimus w-100" type="button"
                                    data-toggle="collapse" data-target={`#collapse${g.id}`} aria-expanded={false}
                                    aria-controls={`collapse${g.id}`}>
                                    {g.title} <span
                                        class="float-right"><i class="accord-icon fa fa-minus" ></i></span>
                                </button>
                            </div>

                            <div id={`collapse${g.id}`} class={`collapse ${index == 0 && 'show'}`} aria-labelledby={`heading${g.id}`}
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <div className="ml-30" dangerouslySetInnerHTML={{ __html: g.content }}></div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
