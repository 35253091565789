import React, { createContext, useEffect, useState } from "react";
import AppRouter from "./AppRouter";
import Loader from "./components/Common/Loader";
import { getResource } from "./service/api";
import Cookies from "js-cookie";
import axios from 'axios';
import ReactGA from 'react-ga';


export const AppContext = createContext()
const client_id = process.env.REACT_APP_CLIENT_ID;
const client_secret = process.env.REACT_APP_CLIENT_SECRET;
const grant_type = process.env.REACT_APP_CLIENT_GRANT_TYPE;
const oauth_url = process.env.REACT_APP_GRANT_TOKEN_URL;

const App = () => {
    const [appState, setAppState] = useState(INITIAL_APP_STATE)

    useEffect(() => {
       // checkExistToken()
       loadDefaultWebsiteDatas()

    }, [])

    const loadDefaultWebsiteDatas = async() => {
        const res = await getResource("defaultdatas")
        setAppState({...appState, defaultdatas: res.data })
    }

    React.useEffect(() => {
        ReactGA.initialize('UA-200273952-2')
        ReactGA.pageview(window.location.pathname + window.location.search)
    
      }, [window.location]);
    

    // const checkExistToken = async() => {
    //     const access_token = Cookies.get('access_token');
    //     const expire_in = Cookies.get('expire_in');
    //     if (typeof(access_token) == "undefined" || typeof(expire_in) == "undefined" || (Math.floor(Date.now() / 1000) > expire_in)) {
    //         await axios.post(oauth_url, { client_id, client_secret, grant_type }, { headers: { 'Content-Type': 'application/json' } }).then((res) => {
    //             Cookies.set('access_token', res.data.access_token)
    //             Cookies.set('expire_in', parseInt(Math.floor(Date.now() / 1000) + res.data.expires_in))
    //         })

    //     }
    //     loadDefaultWebsiteDatas()

    // }

    return ( <AppContext.Provider value = { { appState, setAppState }} >
        <AppRouter/> {!appState.defaultdatas && < Loader/> } </AppContext.Provider>
    );
}

export default App;

const INITIAL_APP_STATE = { searchBarDisplay: false, collapsed: 'on', defaultdatas: null }