import React, { useEffect, useState } from 'react';
import Ariane from '../components/Common/Ariane';
import { useParams } from 'react-router';
import ContentModuleGuichetsItem from '../components/Module/ContentModuleGuichetsItem';
import { getResource } from '../service/api';

export default function Guichet() {
    const {slug} = useParams();
    const [module,setModule]= useState(null)
    useEffect(()=>{
        getResource(`modules?tag=${slug}`).then(res=>{
            setModule(res.data.data[0])
        })
    },[])

    return (
        <div>
            <Ariane  title={module && module.name}/>
            {module && <ContentModuleGuichetsItem guichet={module}/>}
        </div>
    )
}
