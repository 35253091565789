import React from 'react';
import Marquee from "react-fast-marquee"

const MarqueeNews = ({datas}) => {
    return (
        <div className="d-flex alert-zone" id="alertinfo_top" >
            <div className="p-1 pe-2 bd-highlight text-white info-flash">
                <span className="hide4mobile text-uppercase " onload="">Important </span>
            </div>

            <Marquee  pauseOnHover speed={50} gradient={false}>
                {datas.map(marquee=>(
                <a key={marquee.id} href={`/news/${marquee.permalink}`} className="nodecorator">{marquee.title}</a>            
                ))}
            </Marquee>
            {/* <Marquee  pauseOnHover speed={50} gradient={false}>               
                <a  href="#" className="nodecorator">Le FNDA n'octroit aucun pret</a>                            
            </Marquee> */}
        </div>
    );
}

export default MarqueeNews;