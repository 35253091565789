import React from 'react'
import {withRouter} from 'react-router-dom';

const Processus = ({history}) => {

    const redirectPath = (path) =>{
        history.push(path);
      }
    
    return (
    <div id="counter-part" class="bg_cover pt-35 pb-30" style={{backgroundImage: 'url("../../assets/img/bg-1.jpg")'}}>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2 class="text-center text-white mb-5 font-weight-bold">
                        Quel est le processus d'obtention des facilités du FNDA ?
                    </h2>
                    <button class="mx-auto btn btn-success d-block cursor" onClick={()=>redirectPath('/processusselection')}>Cliquez ici</button>
                </div>
            </div>
        </div>
    </div>
    )
}

export default withRouter(Processus)
