import React from 'react';
import Ariane from '../components/Common/Ariane';
import ContentModuleFaqs from '../components/Module/ContentModuleFaqs'

export default function Faqs() {


    return (
        <div>
            <Ariane  title="Foire aux questions"/>
            <ContentModuleFaqs/>
        </div>
    )
}
