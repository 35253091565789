import axios from 'axios';
import Cookies from "js-cookie";

/** Get Access Token For App */
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
const access_token = Cookies.get('access_token');
axios.interceptors.request.use(async function(config) {
    if (typeof(access_token) === 'string') {
        config.headers.Authorization = "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMTYyNTkxNjUzMWRjZWU3NzVlZjVkZGNjOWZmYWZhNjhlYTI3YzUxOThhM2Y5Njg1NDNkODBmNDU3M2JhNWZjZTMxYjVhODE4MDBhNWRhMWIiLCJpYXQiOiIxNjE1Nzk2ODk3LjkzODg0MiIsIm5iZiI6IjE2MTU3OTY4OTcuOTM4ODU1IiwiZXhwIjoiMTYzMTY5NDQ5Ny41NjE1NzAiLCJzdWIiOiIzNjY5Zjg3Ny1mNGI0LTQ5ODQtOGRiZS02NDQ3YWRmOGFkZjkiLCJzY29wZXMiOltdfQ.YOULQ3HNVPfVjV68Po_PUVGYZK9t0Q3-SYlQ7GIUxshKLhLaAay117VoEmskftfKPVim-d9a0I4foiqhOQqm_XnFTMPqM9dZQZPtvSpP_LT4R-fyPynfTPmvXug7JEvFYYWlu2orywKPLytNIspnQWIZ1hirRimRlRt-Jz3aDyQ_BUGb54Q_YzcuezfdSiMBE8cuxoIwqRM8qn5eTRZe7Qoe0AdT6EEyEp-Ikt2S97IGwQ7XCEJRog_hYbZ9uSEhXW6hpO_tMEfBqIm7XaGiDR6Qm2AHSJCE6JNwctb5498eCh-yzglcQ3G3Yx8p4D8k6DkcLpFiudnqseQ-T1yamVpbsuMYrCw1CXnJ1eaeaRBtmDLvPBAi6fEjwJmw7W5sOzF1RKMdTEI_ZT6ogrMZYI0ryLcRnrgoAMdhnOWZrJT7woTpZ758BMgO1c9-yoiAqaymSuv_YW7XbtUSrXsum0YJiQ1ENC0iCg06I5mbiWh4q7g4CkZxJCcUxDuLBuz0poDNwY8Ti0TFEgvyiKFMP9VRmUZwcA2YrwE-KU6xghAiQCW2rzOBKZKDFU1NFLvOhOKJ5IwVZWMupvxE2vYAMdHeYem63LmUkkzt0BQ_BGJ7ijCkf3tp76gp20Trl_47iiVBXe68lqEv1WBNKO2jfkN8Bu5MogcG9PSbN1Cgnkg";
        config.headers['cache-control'] = `no-cache`;
    }
    return config;
}, function(err) {
    console.log('Erreur', err);
    // return Promise.reject(err);
});

/**
 *
 * @param {string} resource_url
 * @param {Array} filters
 * @param {number} limit
 */
export const getResource = (resource_url) => {
    if (typeof(access_token) == 'undefined') {
        //window.location.reload();
    }
    return axios.get(resource_url, { headers: { 'Content-Type': 'application/json' } })
}


/**
 *
 * @param {string} resource_url - Url for API
 * @param {object} data - Data
 * @param {object} headers
 */
export const postResource = (resource_url, data, headers) => {

    return axios.post(resource_url, data, headers)
}

/**
 *
 * @param {string} resource_url
 * @param {number} id
 * @param {object} data
 * @param {object} headers
 */
export const putResource = (resource_url, id, data, headers) => {
    return axios.put(resource_url + '/' + id, data, headers)
}

export const putResourceByUrl = (resource_url, data, headers) => {
    return axios.put(resource_url, data, headers)
}

/**
 *
 * @param {string} resource_url
 * @param {number} id
 */
export const removeResource = (resource_url, id) => {
    return axios.delete(resource_url + '/' + id)
}