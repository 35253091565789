import axios from 'axios';

export default axios.create({
  baseURL: "https://www.googleapis.com/youtube/v3",
  params:{
      part: 'snippet,id',
      maxResults: 8,
      order: 'date',
      type: 'video',
      channelId: process.env.REACT_APP_YOUTUBE_CHANNEL,
      key: process.env.REACT_APP_YOUTUBE_API_KEY
  }
})
