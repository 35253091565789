import React, { useEffect, useState } from 'react'
import Ariane from '../components/Common/Ariane';
import {getResource} from '../service/api';
import youtube from '../service/youtube';
import { useParams } from 'react-router';
import ContentModuleVideos from '../components/Module/ContentModuleVideos';

export default function Videotheque() {

    return (
        <>
            <Ariane title="Vidéothèque"/>
            <ContentModuleVideos />
        </>
    )
}
