import React, { useContext, useState } from 'react';
import { AppContext } from '../../App';
import ContentLoader from 'react-content-loader'
import _ from 'lodash'

const GuichetsLoader = () => (
    <ContentLoader viewBox="0 0 380 400">
        <rect x="15" y="0" rx="5" ry="7" width="100%" height="60" />
        <rect x="15" y="75" rx="5" ry="7" width="100%" height="60" />
        <rect x="15" y="150" rx="5" ry="7" width="100%" height="60" />
    </ContentLoader>
)

const BeforeGuichetsLoader = () => (
    <ContentLoader viewBox="0 0 380 400">
        <rect x="15" y="0" rx="5" ry="7" width="100%" height="100" />
        <rect x="15" y="110" rx="5" ry="7" width="100%" height="100" />
    </ContentLoader>
)


const PUBLIC_URL = process.env.PUBLIC_URL

export default function Guichet() {
    const { appState: { defaultdatas } } = useContext(AppContext)
    return (
        <section id="about-part" class="animated slideInDown pt-50">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        {!defaultdatas && <BeforeGuichetsLoader />}
                        {defaultdatas &&
                            <div>
                                <img src={`${PUBLIC_URL}/assets/img/fnda_benin.jpg`} alt="fnda_benin" class="img-fluid" />
                                <p class="mt-2">
                                    Pour ses interventions, le Fonds National de Développement Agricole (FNDA) dispose de trois (03)
                                    guichets distincts mais complémentaires ci-dessous présentés. Chacun des Guichets dispose de
                                    plusieurs Sous-Guichets.
                    </p></div>}
                    </div>
                    <div class="col-lg-6 ">
                        <div class="accordion" id="accordionExample">
                            {!defaultdatas && <GuichetsLoader />}
                            {defaultdatas && defaultdatas.module_guichets && defaultdatas.module_guichets.data && defaultdatas.module_guichets.data.length > 0 && defaultdatas.module_guichets.data[0].pages && _.sortBy(defaultdatas.module_guichets.data[0].pages,['title']).map((g, index) => (
                                    <div class="card">
                                        <div class="card-header bg-success" id={`heading${g.id}`}>
                                            <h5 class="mb-0">
                                                <button class="btn btn-link text-left text-white accordimus w-100" type="button"
                                                    data-toggle="collapse" data-target={`#collapse${g.id}`} aria-expanded={false}
                                                    aria-controls={`collapse${g.id}`}>
                                                    {g.title} <span
                                                        class="float-right"><i class="accord-icon fa fa-minus" ></i></span>
                                                </button>
                                            </h5>
                                        </div>

                                        <div id={`collapse${g.id}`} class={`collapse ${index == 0 && 'show'}`} aria-labelledby={`heading${g.id}`}
                                            data-parent="#accordionExample">
                                            <div class="card-body">
                                                <div dangerouslySetInnerHTML={{ __html: g.content }}></div>
                                            </div>
                                        </div>
                                    </div>
                            )) }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
