import React, { useContext, useEffect } from 'react';

import BannerSlider from '../components/Home/BannerSlider';
import Partner from '../components/Home/Partner';
import Guichet from '../components/Home/Guichet';
import Newsletter from "../components/Footer/Newsletter";
import Processus from "../components/Home/Processus";
import Actualites from "../components/Home/Actualites";
import Testimonials from '../components/Home/Testimonials';


export default function Home() {
    
    return (
        <>
            <BannerSlider />
            <Guichet />
            <Processus/>
            <Actualites />
            <Partner/>
            <Testimonials/>
            <Newsletter />
        </>
    )
}
