import React, { useContext } from 'react';
import { AppContext } from '../../App';
import Slider from "react-slick";
import "../../assets/css/weblink.css";

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div className="col-md-1 survol" >

        <span
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
              }
            style={{ display: "block" }}
            aria-hidden="true"
            aria-disabled={currentSlide === 0 ? true : false}
            type="button"
        >
           <i class="fa fa-angle-double-left fa-2x" aria-hidden="true"></i>
        </span>
    </div>
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (

    <div className="col-md-1 arrowPositionRight survol">
        <span
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            style={{ display: "block" }}

            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type="button"
        >
            <i class="fa fa-angle-double-right fa-2x" aria-hidden="true"></i>

        </span>
    </div>
)

const Links = () => {
    const { appState: { defaultdatas } } = useContext(AppContext)

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        nextArrow: <SlickArrowRight />,
        prevArrow: <SlickArrowLeft />,
        autoplaySpeed: 3000,
        focusOnSelect: true,
        centerMode: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (

        <div className=" pb-4 mb-3 weblink">
        <h5 className="text-center text-white mb-1">LIENS UTILES</h5>
        <Slider {...settings}>
            {defaultdatas && defaultdatas.weblinks.map(link => (
                <div key={link.id} className="rounded border p-1  text-white">
                    <a className="text-white liens" href={link.access_link} target="_blank">
                        <span className="lien-icon"><i className="fa fa-link"></i></span>
                        {link.name}
                    </a>
                </div>
            ))}
        </Slider>

    </div>
    )
}

export default Links