import React, {useEffect, useState} from "react";
import ContentModuleDocument from './ContentModuleDocument';
import ContentModuleContact from './ContentModuleContact';
import ContentModulePartners from './ContentModulePartners';
import ContentModuleNews from './ContentModuleNews';
import NotFound from '../Common/NotFound';
import ContentModuleTeam from "./ContentModuleTeam";
import ContentModuleGuichets from "./ContentModuleGuichets";
import ContentModulePhotos from "./ContentModulePhotos";
import ContentModuleProjets from "./ContentModuleProjets";






const ContentModule = ({module}) => {
    
    const type = ["partners", "documentations", "news", "contacts","medias","team","pages", "projets"];
    return ( 
        <>
        { (type.filter(t => t == module.type_module).length == 0) && <NotFound/>}
        { module.type_module === type[0] && <ContentModulePartners module={module}/> }
        { module.type_module === type[1] && <ContentModuleDocument module={module} /> }
        { module.type_module === type[2] && <ContentModuleNews module={module}/> }
        { module.type_module === type[3] && <ContentModuleContact module={module}/> }    
        { module.type_module === type[4] && <ContentModulePhotos module={module}/> } 
        { module.type_module === type[5] && <ContentModuleTeam module={module}/> }
        { module.type_module === type[6] && <ContentModuleGuichets module={module}/> } 
        { module.type_module === type[7] && <ContentModuleProjets module={module}/> }     


    </>
     );
}
 
export default ContentModule;