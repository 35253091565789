import React from 'react'

export default function ContentModuleGuichets({ module }) {

    return (
        <section class="pt-85 pb-70">
        <div class="container">
            <div class="row mt-4 border-bottom">
                {module.children.map( m => (
                <div className="col-lg-4 col-sm-12">
                     <a href={`/guichets/FNDA/${m.tag}`}  class="w-100">
                         <div class="fin-card " style={{'--color-code': '#00963f'}}>
                             <div class="fin-dot-div">
                                 <span class="fin-dot">
                                     <i class="fa fa-user"></i>
                                     <i class="si icon-frame-1"></i>
                                 </span>
                             </div>
                             <div class="fin-card-body">
                                 <p class="fin-card-text" title="Guichet 1">
                                     {m.name}</p>
                             </div>
                         </div>
                     </a>
                 </div>
                ))}
 
            </div>

        </div>
    </section>


    )
}