import React from 'react';
import moment from 'moment'
import {
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    TelegramShareButton,
} from "react-share";

const directory_doc = process.env.REACT_APP_UPLOAD_DIRECTORY;

const ContentNews = ({ news }) => {

    return (
        <section id="blog-single" className="pt-90 pb-120 gray-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="blog-details mt-30">
                            <div className="thum">
                                <img src={`${directory_doc}/${news.fichier.filename}`} alt="Blog Details" />
                            </div>
                            <div className="cont">
                                <h3>{news.title}</h3>
                                <ul>
                                    <li><a href="#"><i className="fa fa-calendar"></i>{moment(news.created_at).format('DD-MMM-YYYY')}</a></li>
                                    {/* <li><a href="#"><i className="fa fa-user"></i>{`${news.users.firstname} ${news.users.lastname}`}</a></li> */}
                                    <li><a href="#"><i className="fa fa-tags"></i>{news.modules.name}</a></li>
                                </ul>
                                <div dangerouslySetInnerHTML={{ __html: news.content }}></div>
                                <ul className="share">
                                    <li className="title">Partage :</li>
                                    {/* <li><a className='' href="#"><i className="fab fa-facebook-f "></i></a></li>
                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fab fa-whatsapp"></i></a></li>
                                    <li><a href="#"><i className="fab fa-telegram"></i></a></li>
                                    <li><a href="#"><i className="fab fa-linkedin"></i></a></li> */}
                                    <FacebookShareButton className="shared m-1" title={news && news.title} url={`${process.env.REACT_APP_BASE_URL}/${`news/${news.permalink}`}`}>
                                        <i
                                            className="fab fa-facebook"></i>
                                    </FacebookShareButton>
                                    <WhatsappShareButton className="shared m-1" title={news && news.title} url={`${process.env.REACT_APP_BASE_URL}/${`news/${news.permalink}`}`}>
                                        <i
                                            className="fab fa-whatsapp"></i>
                                    </WhatsappShareButton>
                                    <TelegramShareButton className="shared m-1" title={news && news.title} url={`${process.env.REACT_APP_BASE_URL}/${`news/${news.permalink}`}`}>
                                        <i
                                            className="fab fa-telegram"></i>
                                    </TelegramShareButton>
                                    <TwitterShareButton className="shared m-1" title={news && news.title} url={`${process.env.REACT_APP_BASE_URL}/${`news/${news.permalink}`}`}>
                                        <i
                                            className="fab fa-twitter"></i>
                                    </TwitterShareButton>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default ContentNews;