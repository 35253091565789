import React, { useEffect, useState } from 'react';
import { getResource } from "../../service/api";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment'
import Select from 'react-select'
import {Alert} from 'react-bootstrap';


export default function ContentModuleDocument({ module }) {

    const directory_doc = process.env.REACT_APP_UPLOAD_DIRECTORY;
    const [projets, setProjets] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [search, setSearch] = useState(null)
    const [numberPage, setNumberPage] = useState([])
    const [modal, setModal] = useState(false)
    const [query, setQuery] = useState("");
    const [selection, setSelection] = useState();
    const [zone, setZone] = useState('autres')
    const [zones, setZones] = useState([])
    const [dep, setDep] = useState([])
    const [alert, setAlert] = useState(null);
    const [pda, setPda] = useState([])
    const [recharge, setRecharge] = useState([])
    const [currentProjet, setCurrentProjet] = useState(null)
    const [button, setButton] = useState(<i className="fa fa-paper-plane"></i>);

    const STATUS = [
        {value:'0',label:'Non débuté'},
        {value:'-1',label:'En cours'},
        {value:'1',label:'Terminé'},
    ]

    const Champs = [
        {value:'Financeur',label:'Financeur'},
        {value:'Montant',label:'Montant'},
        // {value:'Description',label:'Description'},
    ]

    useEffect(() => {
        getResource(`projets?module_id=${module.id}&page=${currentPage}&public=1`).then(res => {
            setProjets(res.data.data)
            setRecharge(res.data.data)
            let array_pages = Math.ceil(parseInt(res.data.total) / parseInt(res.data.per_page))
            array_pages = Array.from(Array(array_pages).keys())
            setNumberPage(array_pages);
        })

        getResource('departements').then(res => {
            setDep(res.data.map(d => ({ value: d.departement, label: d.departement })))
        })

        getResource('pdas').then(res => {
            setPda(res.data.map(d => ({ value: d.pda, label: d.pda })))
        })
    }, [currentPage])

    useEffect(() => {
        if(search != null){
            getResource(`projets?module_id=${module.id}&${zone}=${search}&public=1`).then(res => {
                setProjets(res.data.data)
                let array_pages = Math.ceil(parseInt(res.data.total) / parseInt(res.data.per_page))
                array_pages = Array.from(Array(array_pages).keys())
                setNumberPage(array_pages);
            })
        }
    }, [search])

    useEffect(() => {
        setZones(null)
        setSearch(null)
        if (zone == 'pole_developpement') setZones(pda)
        if (zone == 'status') setZones(STATUS)
        if (zone == 'autres') setZones(Champs)
        if (zone == 'departement') setZones(dep)
        setProjets(recharge)
    }, [zone])

    const getQuery = (e) => {
        setQuery(e.target.value);
    }

    const HandleSubmit = (e) => {
        e.preventDefault();
        setButton(<i class="fa fa-spinner fa-pulse fa-1x fa-fw"></i>)
        if(query && selection) {
            getResource(`projets?module_id=${module.id}&${selection}=${query}&public=1`).then(res => {
                setProjets(res.data.data)
                let array_pages = Math.ceil(parseInt(res.data.total) / parseInt(res.data.per_page))
                array_pages = Array.from(Array(array_pages).keys())
                setNumberPage(array_pages);
                setButton(<i className="fa fa-paper-plane"></i>)
            })
        }else{
            setAlert({ success: false, message: 'Veillez remplir tout les champs' });
            setButton(<i className="fa fa-paper-plane"></i>)
        }
    }

    const toggle = (current = null) => {
        setModal(!modal)
        setCurrentProjet(current)
    }

    return (
        <div id="simple-page" class="pt-30 pb-30 bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                    {
                        alert && <Alert style={{color:'black', marginTop:'10px'}} variant={alert.success ? 'success' : 'danger'} onClose={() => setAlert(null)} dismissible>
                        { alert.message }
                        </Alert>
                    }
                        <div >
                            <h6 className=" mt-5  criteria mb-3  ">Rechercher un projet</h6>
                            <div className="btn-group special " role="group" aria-label="Indicateurs clés">
                                <button type="button" className={`btn ${zone == 'status' && 'btn-success'}`} onClick={() => setZone('status')}>Statut</button>
                                <button type="button" className={`btn ${zone == 'pole_developpement' && 'btn-success'}`} onClick={() => setZone('pole_developpement')}>Pôles de développement</button>
                                <button type="button" className={`btn ${zone == 'departement' && 'btn-success'}`} onClick={() => setZone('departement')}>Départements</button>
                                <button type="button" className={`btn ${zone == 'autres' && 'btn-warning'}`} onClick={() => setZone('autres')} >Autres</button>
                            </div>
                            {zones && zone != 'autres' && <div className="zone_area mb-3">
                                <Select options={zones} onChange={(search) => setSearch([search.value])} />
                            </div>}
                            {Champs && zone == 'autres' && <div className="zone_area mb-3">
                                <Select options={Champs} onChange={(selection) => setSelection([selection.value])} />
                                <div className="input-group mb-3 mt-3">
                                    <input onChange={getQuery}  type="text" className="form-control newsletter-input" placeholder="Rechercher un projet" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                    <div className="input-group-append" >
                                        <a type="" onClick={HandleSubmit} className="input-group-text site-secondary-bgcolor" id="basic-addon2">{button}</a>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                <div class="row mt-4 border-bottom">
                    {projets.map((pro, index) => (
                        <div class="col-lg-4 col-sm-12" key={index}>
                            <a onClick={() => toggle(pro)} className="cursor">
                                <div class="fin-card" style={{ '--color-code': '#44cf43' }} >
                                    <div class="fin-dot-div">
                                        <span class="fin-dot">
                                            <i class="fa fa-tractor"></i>
                                            <i class="si icon-frame-1"></i>
                                        </span>
                                    </div>
                                    <div class="fin-card-body">
                                        <small class=""
                                            title={pro.nom}>
                                            {pro.nom.substring(0, 100)}</small>
                                    </div>
                                </div>
                            </a>

                        </div>
                    ))}
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <nav className="courses-pagination mt-50 pb-3">
                        <ul className="pagination justify-content-center">
                            <li className="page-item cursor">
                                <a onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} aria-label="Précédent" >
                                    <i className="fa fa-angle-left"></i>
                                </a>
                            </li>
                            {
                                numberPage.map((n) => (
                                    <li key={`page${n + 1}`} className="page-item cursor"><a className={currentPage == (n + 1) && 'active'} onClick={() => setCurrentPage(n + 1)}>{n + 1}</a></li>
                                ))
                            }
                            <li className="page-item cursor">
                                <a onClick={() => currentPage < numberPage.length && setCurrentPage(currentPage + 1)} aria-label="Suivant">
                                    <i className="fa fa-angle-right"></i>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <Modal isOpen={modal} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>{currentProjet && currentProjet.nom}</ModalHeader>
                <ModalBody>
                    { currentProjet &&
                     <div className=" col-md-12">
                        <table className='table table-bordered table-striped'>
                        <thead>
                            <tr>
                                <th className='text-center' scope="col" colspan="2">Détails</th>
                            </tr>
                        </thead>
                            <tbody >
                                <tr>
                                    <th scope="row">Bailleur</th>
                                    <td>{currentProjet.financeur}</td>
                                </tr>
                                {currentProjet.montant > 0 &&<tr>
                                    <th scope="row">Montant</th>
                                    <td>{currentProjet.montant + 'F CFA'}</td>
                                </tr>}
                                <tr>
                                    <th scope="row">Pôles de développement</th>
                                    <td>{"PDA "+currentProjet.pole_developpement}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Statut</th>
                                    <td>{currentProjet.status  == -1 ? "En cours" : currentProjet && currentProjet.status == 1 ? "Terminé" : "Non débuté"}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Taux d'exécution</th>
                                    <td>{currentProjet.taux_execution+'%'}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Début</th>
                                    <td>{moment(currentProjet.debut).format('DD-MM-YYYY')}</td>
                                </tr>
                                { currentProjet.fin != null && <tr>
                                    <th scope="row">Fin</th>
                                    <td>{moment(currentProjet.fin).format('DD-MM-YYYY')}</td>
                                </tr>}
                            </tbody>
                        </table>
                        <div class="col-md-12">
                            <h5>Description</h5>
                            <span>{currentProjet && currentProjet.description}</span>
                        </div>
                    </div>}
                </ModalBody>
                {currentProjet && currentProjet.fichier &&
                <ModalFooter>
                    
                    <Button color="info" onClick={toggle}> <a href={`${directory_doc}/${currentProjet.fichier.filename}`} style={{color:"white"}}>Télécharger</a> </Button>
                   
                </ModalFooter> }{' '}
            </Modal>

        </div>
    )
}
