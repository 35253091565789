import React, { useEffect, useState } from 'react'
import Ariane from '../components/Common/Ariane';
import ContentPage from '../components/Page/ContentPage';
import {getResource} from '../service/api';
import Loader from '../components/Common/Loader'
import { useParams } from 'react-router';

export default function Page() {

    const {slug} = useParams();
    const[currentpage, setCurrentpage] = useState(null);

    useEffect(() => {

        getResource(`pages?permalink=${slug}&public=1`).then(res => {
            if(res.data.data.length>0){
                setCurrentpage(res.data.data[0]);
            }
            
        })
    }, []);


    return (
        <>
        {!currentpage && <Loader/>}
            {currentpage &&<Ariane title={currentpage.title}/>}
            {currentpage &&<ContentPage page={currentpage}/>}
        </>
    )
}
