import React from 'react';
import Ariane from '../components/Common/Ariane';
import ContentModuleProcessus from '../components/Module/ContentModuleProcessus'

export default function ProcessusSelection() {


    return (
        <div>
            <Ariane  title="Processus de sélection"/>
            <ContentModuleProcessus/>
        </div>
    )
}
