import React, { useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'
import _ from 'lodash'
import "../../assets/css/guichet.css";

const GuichetsLoader = () => (
    <ContentLoader viewBox="0 0 380 400">
        <rect x="15" y="0" rx="5" ry="7" width="100%" height="60" />
        <rect x="15" y="75" rx="5" ry="7" width="100%" height="60" />
        <rect x="15" y="150" rx="5" ry="7" width="100%" height="60" />
    </ContentLoader>
)

export default function ContentModuleGuichetsItem({ guichet }) {
const {pages} = guichet
const [currentPage, setCurrentPage] = useState(_.sortBy(pages,['created_at'])[0].id)


return (
        <section class="pt-15 pb-10 guichet">
        <div class="container">
            <div class="row mt-4 border-bottom">
            <div class="accordion" id="accordionExample">
                            {/* {!pages && <GuichetsLoader />} */}
                            { _.sortBy(pages,['created_at']).map((g, index) => (
                                    <div class="card">
                                        <div class="card-header bg-success" id={`heading${g.id}`}>
                                            <h5 class="mb-0">
                                                <button class="btn btn-link text-left text-white accordimus w-100" type="button"
                                                    data-toggle="collapse" data-target={`#collapse${g.id}`} aria-expanded={false}
                                                    aria-controls={`collapse${g.id}`} onClick={()=>{setCurrentPage(g.id)}}>
                                                    {g.title} <span
                                                        class="float-right"><i class="accord-icon fa fa-minus" ></i></span>
                                                </button>
                                            </h5>
                                        </div>

                                        <div id={`collapse${g.id}`} class={`collapse ${(currentPage == g.id )?'show':''}`} aria-labelledby={`heading${g.id}`}
                                            data-parent="#accordionExample">
                                            <div class="card-body pl-50">
                                                <div dangerouslySetInnerHTML={{ __html: g.content }}></div>
                                            </div>
                                        </div>
                                    </div>
                            )) }
                        </div>
                      
 
            </div>

        </div>
    </section>  


    )
}