import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getResource } from '../../service/api'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ContentModuleStatisticViewer from './ContentModuleStatisticViewer';

export default function ContentModuleStatistic() {

    const { slug } = useParams();
    const [indicateurs, setIndicateurs] = useState([])
    const [modal, setModal] = useState(false)
    const [currentIndicateur, setCurrentIndicateur] = useState(null)
    const [type_campagne, setTypeCampagne] = useState("Prévision")
    const [view, setView] = useState("line")

    useEffect(() => {
        getResource(`campagnesStatistique?type_production=${slug}`).then(res => {
            const keyData = Object.keys(res.data.data)
            const datas = res.data.data
            let indicateursData = []
            keyData.map(key => {
                indicateursData.push({ categorie: key, items: datas[key] })
            })
            setIndicateurs(indicateursData)
        })
    }, [])
    const toggle = (current = null) => {
        setModal(!modal)
        setCurrentIndicateur(current)
    }

    return (
        <div id="simple-page" className="pt-90 pb-120 bg-white">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-4">
                        <h4 className="mb-3">Les indicateurs</h4>
                        <div className="list-group" id="list-tab" role="tablist">
                            <a className={`list-group-item list-group-item-action ${!slug &&  'active'} `} data-toggle="list"
                                href="/statistics/" role="tab" aria-controls="toutes-les-thématiques">Toutes les thématiques <i
                                    className="fa fa-arrow-right float-right"></i></a>
                            <a className={`list-group-item list-group-item-action ${slug === "animale" && 'active'} `} id="production_animale" data-toggle="list"
                                href="/statistics/animale" role="tab" aria-controls="production-animale"> Production Animale <i
                                    className="fa fa-arrow-right float-right"></i></a>
                            <a className={`list-group-item list-group-item-action ${slug === "vegetale" && 'active'} `} id="production_vegetale" data-toggle="list"
                                href="/statistics/vegetale" role="tab" aria-controls="production-vegetale">Production Végétale <i
                                    className="fa fa-arrow-right float-right"></i></a>
                            <a className={`list-group-item list-group-item-action ${slug === "halieutique" && 'active'} `} id="production_halieutique" data-toggle="list"
                                href="/statistics/halieutique" role="tab" aria-controls="production_halieutique">Production Halieutique <i
                                    className="fa fa-arrow-right float-right"></i></a>
                            <a className={`list-group-item list-group-item-action ${slug === "serie" && 'active'} `} id="séries" data-toggle="list"
                                href="/statistics/serie" role="tab" aria-controls="settings">Séries <i
                                    className="fa fa-arrow-right float-right"></i></a>
                            <a className={`list-group-item list-group-item-action ${slug === "pda" && 'active'} `} id="pôles_de_développement" data-toggle="list"
                                href="/statistics/pda" role="tab" aria-controls="settings">Pôles de Développement Agricole <i
                                    className="fa fa-arrow-right float-right"></i></a>

                        </div>
                    </div>
                    <div className="col-sm-12 col-md-8">
                        <div className="card">
                            <div className="card-body">
                                <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="home-tab" data-toggle="tab" href="#indicateursclés" role="tab"
                                            aria-controls="indicateursclés" aria-selected="true">Indicateurs Clés
                                </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="profile-tab" data-toggle="tab" href="#tous-les-indicateurs" role="tab"
                                            aria-controls="tous-les-indicateurs" aria-selected="false">Tous les indicateurs</a>
                                    </li>
                                    <li>
                                        <div className="input-group ml-5 w-75">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text bg-transparent border-right-0" id="basic-addon1"><i className="fa fa-search"></i></span>
                                            </div>
                                            <input type="text" className="form-control border-left-0" placeholder="Filtre..." aria-label="Username"
                                                aria-describedby="basic-addon1" />
                                        </div>
                                    </li>
                                </ul>
                                <div className="tab-content" >
                                    <div className="tab-pane fade show active pt-2" id="indicateursclés" role="tabpanel"
                                        aria-labelledby="indicateursclés">
                                        <div className="tab-content" id="nav-tabContent">

                                            <div className="tab-pane fade show active" id="toutes-les-thématiques" role="tabpanel"
                                                aria-labelledby="toutes-les-thématiques">
                                                {
                                                    indicateurs && indicateurs.map((indicateur, index) => (
                                                        <div key={'indicateur' + index}>
                                                            <h4 className="mb-3 mt-3" >{indicateur.categorie}</h4>
                                                            <div className="row">
                                                                {
                                                                    indicateur.items.map(ind => (
                                                                        <div key={ind.culture} className="col-4 pb-4 cursor"><a onClick={() => toggle(ind)} className="text-capitalize  text-bold">{ind.culture.toLowerCase()}</a></div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }

                                            </div>

                                            <div className="tab-pane fade" id="series" role="tabpanel"
                                                aria-labelledby="séries">
                                                <h4 className="mb-3">Séries</h4>
                                            </div>
                                            <div className="tab-pane fade" id="pôles-de-développement" role="tabpanel"
                                                aria-labelledby="pôles-de-développement">
                                                <h4 className="mb-3">Pôles de Développement Agricole</h4>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <Modal isOpen={modal} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>Visualisation de données   {currentIndicateur && currentIndicateur.culture}</ModalHeader>
                <ModalBody>


                    <div className="card">
                        <div className="card-body">
                            <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <a className={`nav-link ${view=='line' && 'active'}`} id="home-tab" data-toggle="tab" onClick={()=>setView("line")} role="tab"
                                        aria-controls="indicateursclés" aria-selected="true"> Linéaire
                                </a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${view=='bar' && 'active'}`} id="home-tab" data-toggle="tab" onClick={()=>setView("bar")}  role="tab"
                                        aria-controls="indicateursclés" aria-selected="true"> Histogramme
                                </a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${view=='map' && 'active'}`}id="home-tab" data-toggle="tab" onClick={()=>setView("map")} role="tab"
                                        aria-controls="indicateursclés" aria-selected="true"> Carte
                                </a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${view=='table' && 'active'}`} id="home-tab" data-toggle="tab" onClick={()=>setView("table")} role="tab"
                                        aria-controls="indicateursclés" aria-selected="true"> Tableau
                                </a>
                                </li>
                            </ul>
                            <div className="tab-content" >
                                {currentIndicateur && <ContentModuleStatisticViewer indicateur={currentIndicateur} type_production={slug} type_campagne={type_campagne} view={view} />}
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                <Button color="info" onClick={toggle}>Télécharger</Button>{' '}                
                </ModalFooter>
            </Modal>
        </div>


    )
}
