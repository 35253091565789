import React, { useEffect, useState } from 'react'
import { getResource } from '../../service/api'
import ContentLoader from 'react-content-loader'
import _ from 'lodash'
import "../../assets/css/page.css";

const EtapesLoader = () => (
    <ContentLoader viewBox="0 0 380 400">
        <rect x="15" y="0" rx="5" ry="7" width="100%" height="60" />
        <rect x="15" y="75" rx="5" ry="7" width="100%" height="60" />
        <rect x="15" y="150" rx="5" ry="7" width="100%" height="60" />
    </ContentLoader>
)

export default function ContentModuleProcessus  () {
    const [etapes, setEtapes] = useState(null)

    useEffect(() => {
        getResource(`modules?tag=@processus_selection`).then(res => {
            let pages = res.data.data[0].pages;
            setEtapes(pages)
        })

    }, [])

    return (
        <div class="container-fluid ml-50  mt-35 mb-35 page">
        <div class="accordion etapes row" id="accordionExample">
            <div class="col-md-8">
        {!etapes && <EtapesLoader />}
        {etapes&&_.sortBy(etapes,['title']).filter(etape => etape.public == 1).map((g, index) => (
                <div class="card" key={index}>
                    <div class="card-header bg-success" id={`heading${g.id}`}>
                            <button class="btn btn-link text-left text-white accordimus w-100" type="button"
                                data-toggle="collapse" data-target={`#collapse${g.id}`} aria-expanded={false}
                                aria-controls={`collapse${g.id}`}>
                                {g.title} <span
                                    class="float-right"><i class="accord-icon fa fa-minus" ></i></span>
                            </button>
                    </div>

                    <div id={`collapse${g.id}`} class={`collapse ${index == 0 && 'show'}`} aria-labelledby={`heading${g.id}`}
                        data-parent="#accordionExample">
                        <div class="card-body">
                            <div className="ml-30" dangerouslySetInnerHTML={{ __html: g.content }}></div>
                        </div>
                    </div>
                </div>
        )) }
    </div>
    <div class="col-md-3 organeasid ">
        <h6>NOMENCLATURE DES METIERS ET SERVICES AGRICOLES AU BENIN</h6>
        <p>Vous souhaitez vous lancer dans l'agriculture, nous vous invitons à consulter la nomenclature des métiers et services agricoles au Bénin, un document qui pourra vous éclairer sur les métiers agricoles ainsi que les formations.</p>
        <p><a className="btn btn-lg btn-success center" href="https://apifnda.agriculture.gouv.bj/public/storage/uploads/nommenclature.pdf">TELECHARGER</a></p>
    </div>
    </div>
    </div>
    )
}
