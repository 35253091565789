import React, { useEffect, useState } from 'react'
import { getResource } from '../../service/api'
import ContentLoader from 'react-content-loader'
import _ from 'lodash'
import "../../assets/css/page.css"
import ig from "../../assets/images/image-fnda2-550x510.jpg";

const EtapesLoader = () => (
    <ContentLoader viewBox="0 0 380 400">
        <rect x="15" y="0" rx="5" ry="7" width="100%" height="60" />
        <rect x="15" y="75" rx="5" ry="7" width="100%" height="60" />
        <rect x="15" y="150" rx="5" ry="7" width="100%" height="60" />
    </ContentLoader>
)

export default function ContentModulePartenaire  () {
    const [etapes, setEtapes] = useState(null)

    useEffect(() => {
        getResource(`modules?tag=@partenaire_strategique`).then(res => {
            let pages = res.data.data[0].pages;
            setEtapes(pages)
        })

    }, [])

    return (
        <div className="container mt-35 mb-35 page">
        <div className="accordion etapes row" id="accordionExample">
        <div className="col-md-6 organeasid ">
            <p>Les partenaires stratégiques d’opérationnalisation du Fonds National de Développement Agricole (FNDA) sont :</p>
                <div className="elementor-element elementor-element-3110b52 elementor-icon-list--layout-traditional elementor-widget elementor-widget-icon-list" data-id="3110b52" data-element_type="widget" data-widget_type="icon-list.default">
                    <div className="elementor-widget-container">
                        <ul className="elementor-icon-list-items">
                            <li className="elementor-icon-list-item">
                                <span className="elementor-icon-list-icon"><i aria-hidden="true" className="fas fa-dot-circle"></i></span>
                                <span className="elementor-icon-list-text">Les Agences Territoriales de Développement Agricole (ATDA)</span>
                            </li>
                            <li className="elementor-icon-list-item">
                                <span className="elementor-icon-list-icon"><i aria-hidden="true" className="fas fa-dot-circle"></i></span>
                                <span className="elementor-icon-list-text">Les Directions Départementales de l’Agriculture, de l’Elevage et de la Pêche (DDAEP)</span>
                            </li>
                            <li className="elementor-icon-list-item">
                                <span className="elementor-icon-list-icon"><i aria-hidden="true" className="fas fa-dot-circle"></i></span>
                                <span className="elementor-icon-list-text">Les Prestataires de Services Non Financiers (PSNF)</span>
                            </li>
                            <li className="elementor-icon-list-item">
                                <span className="elementor-icon-list-icon"><i aria-hidden="true" className="fas fa-dot-circle"></i></span>
                                <span className="elementor-icon-list-text">Les Prestataires de Services Financiers (PSF)</span>
                            </li>
                            <li className="elementor-icon-list-item">
                                <span className="elementor-icon-list-icon"><i aria-hidden="true" className="fas fa-dot-circle"></i></span>
                                <span className="elementor-icon-list-text">Les Communes</span>
                            </li>
                            <li className="elementor-icon-list-item">
                                <span className="elementor-icon-list-icon"><i aria-hidden="true" className="fas fa-dot-circle"></i></span>
                                <span className="elementor-icon-list-text">Et les Organisation des Producteurs Agricoles et Faîtières des coopératives de producteurs. </span>
                            </li>
                        </ul>
                    </div>
                    <img src={ig} />
                </div>   
        </div>

        <div className="col-md-6">
        {!etapes && <EtapesLoader />}
        {etapes&&_.sortBy(etapes,['title']).filter(etape => etape.public == 1).map((g, index) => (
                <div className="card" key={index}>
                    <div className="card-header colors  bg-success" id={`heading${g.id}`}>
                            <button className="btn btn-link text-left text-white accordimus w-100" type="button"
                                data-toggle="collapse" data-target={`#collapse${g.id}`} aria-expanded={false}
                                aria-controls={`collapse${g.id}`}>
                                {g.title} <span
                                    className="float-right"><i className="accord-icon fa fa-minus" ></i></span>
                            </button>
                    </div>

                    <div id={`collapse${g.id}`} className={`collapse ${index == 0 && 'show'}`} aria-labelledby={`heading${g.id}`}
                        data-parent="#accordionExample">
                        <div className="card-body">
                            <div className="ml-30" dangerouslySetInnerHTML={{ __html: g.content }}></div>
                        </div>
                    </div>
                </div>
        )) }
    </div>
    </div>
    </div>
    )
}
