import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App';
import { useParams } from 'react-router';
import {postResource} from '../../service/api';
import {Alert} from 'react-bootstrap';


export default function SearchBar() {

    const {appState, setAppState} = useContext(AppContext);
    const [query, setQuery] = useState([]);
    const {slug} = useParams();
    const [alert, setAlert] = useState(null);
    const [button, setButton] = useState(<i className="fa fa-search"></i>);
    const [searchdata, setSearchdata] = useState([]);
    

    const onSubmit = (e) => {
        setButton(<i class="fa fa-spinner fa-pulse fa-1x fa-fw"></i>)
        e.preventDefault();

        if(query.length >= 3){
            setButton(<i className="fa fa-search"></i>)
            return  window.location.assign(`/recherche/${query}`);
        }else{
            setButton(<i className="fa fa-search"></i>)
            setAlert({ success: false, message: 'Saisissez au moins 03 caractères' });
        }

    }

    const getQuery = (e) => {
        setQuery(e.target.value);
    }



    return (
        <div className="search-box" style={{display:appState.searchBarDisplay?"block":"none"}}>
            <div className="search-form">
                <div className="closebtn" onClick={()=>{setAppState({...appState, searchBarDisplay:false})}} >
                    <span></span>
                    <span></span>
                </div>
                <div className="container col-md-8 offset-4 mt-200 row"> 
                    {
                        alert && <Alert style={{color:'black', marginTop:'10px'}} variant={alert.success ? 'success' : 'danger'} onClose={() => setAlert(null)} dismissible>
                        { alert.message }
                        </Alert>
                    }
                </div>
                <form onSubmit={onSubmit}>
                    <input type="text" required onChange={getQuery} name="query" placeholder="Recherchez un mot clé" />
                    <button>{button}</button>
                </form>
            </div>
        </div>
    )
}
