import React, { useContext, useEffect, useState } from "react";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import SlideToUp from "./components/Common/SlideToUp";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import NotFound from "./components/Common/NotFound";
import Page from "./pages/Page";
import Home from './pages/Home';
import Module from "./pages/Module";
import { AppContext } from "./App";
import News from "./pages/News";
import Welcome from "./pages/Welcome";
import ServicePage from "./pages/Services";
import Statistique from "./pages/Statistique";
import Mediatheque from "./pages/Mediatheque";
import MediathequeItems from "./pages/MediathequeItems";
import ProcessusSelection from "./pages/Processus_Selection";
import Faqs from "./pages/Faqs";
import PartenaireStrategique from "./pages/Partenaire_Strategique";
import Guichet from "./pages/Guichet";
import Videotheque from "./pages/Videotheque";
import SearchView from "./pages/SearchView";




const AppRouter = (props) => {
  const { appState, setAppState } = useContext(AppContext)

  return (
    <Router>
      {appState.defaultdatas && <Header />}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/page/:menu_parent/:slug" component={Page} />
        <Route path="/page/:slug" component={Page} />
        <Route path="/news/:slug" component={News} />
        <Route path="/section/:menu_parent/:slug" component={Module} />
        <Route path="/welcome" component={Welcome} />
        {/* <Route path="/services" component={ServicePage} /> */}
        <Route path="/statistics/:menu_parent/:slug" component={Statistique} />
        <Route path="/mediatheque" component={Mediatheque} />
        <Route path="/processusselection" component={ProcessusSelection} />
        <Route path="/faqs" component={Faqs} />
        <Route path="/partenaire_strategique" component={PartenaireStrategique} />
        <Route path="/guichets/:menu_parent/:slug" component={Guichet} />
        <Route path="/mediatheque-items/:menu_parent/:slug" component={MediathequeItems} />
        <Route path="/videotheque" component={Videotheque} />
        <Route path="/recherche/:slug" component={SearchView} />
        <Route path="*" component={NotFound} />
      </Switch>
      <Footer />
      <SlideToUp />
    </Router>

  )
}



export default AppRouter;
