import React, { useEffect, useState } from 'react'
import Gallery from 'react-grid-gallery';
import { getResource } from '../../service/api';
import { useParams } from 'react-router';
import ContentLoader, { Facebook } from 'react-content-loader'

const directory = process.env.REACT_APP_UPLOAD_DIRECTORY
const MyLoader = () => (
    <ContentLoader viewBox="0 0 380 400">
      {/* Only SVG shapes */}    
      <rect x="15" y="0" rx="5" ry="7" width="170" height="170" />
      <rect x="195" y="0" rx="5" ry="7" width="170" height="170" />
      <rect x="15" y="180" rx="5" ry="7" width="170" height="170" />
      <rect x="195" y="180" rx="5" ry="7" width="170" height="170" />
    </ContentLoader>
  )

export default function ContentModulePhotosItems({module}) {

    const [currentPage, setCurrentPage] = useState(1)
    const [numberPage, setNumberPage] = useState([])
    const [mediaPhotos, setModulePhotos] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)

        getResource(`medias?module_id=${module.id}&page=${currentPage}&public=1`).then(res => {
            setModulePhotos(res.data.data.map( data =>(
                {
                    src: `${directory}/${data.fichier.filename}`,
                    thumbnail:`${directory}/${data.fichier.filename}`,
             
                }
            )));
            setLoading(false)
            let array_pages = Math.ceil(parseInt(res.data.total) / parseInt(res.data.per_page))
            array_pages = Array.from(Array(array_pages).keys())
            setNumberPage(array_pages);
        })
    }, [currentPage,module])

    return (

            <div className="container content-mediatheque-items">
                <div className="row">
                    <div className="col-md-12 mt-10">
                    {loading && <MyLoader />}
                    { mediaPhotos.length > 0 ? <Gallery images={mediaPhotos} /> : <h3> Pas de medias </h3>}
                    </div>
                </div>
                {numberPage > 1 &&<div className="row">
                    <div className="col-lg-12">
                        <nav className="courses-pagination mt-50 pb-3">
                            <ul className="pagination justify-content-center">
                                <li className="page-item cursor">
                                    <a onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} aria-label="Précédent" >
                                        <i className="fa fa-angle-left"></i>
                                    </a>
                                </li>
                                {
                                    numberPage.map((n) => (
                                        <li key={`page${n + 1}`} className="page-item cursor"><a className={currentPage == (n + 1) && 'active'} onClick={() => setCurrentPage(n + 1)}>{n + 1}</a></li>
                                    ))
                                }
                                <li className="page-item cursor">
                                    <a onClick={() => currentPage < numberPage.length && setCurrentPage(currentPage + 1)} aria-label="Suivant">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>}
            </div>
    )
}
